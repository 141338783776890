exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-event-event-season-event-chapter-event-route-event-schedule-id-tsx": () => import("./../../../src/pages/event/{event.season}/{event.chapter}/{event.route}-{event.scheduleId}.tsx" /* webpackChunkName: "component---src-pages-event-event-season-event-chapter-event-route-event-schedule-id-tsx" */),
  "component---src-pages-event-event-season-event-chapter-tsx": () => import("./../../../src/pages/event/{event.season}/{event.chapter}.tsx" /* webpackChunkName: "component---src-pages-event-event-season-event-chapter-tsx" */),
  "component---src-pages-event-event-season-tsx": () => import("./../../../src/pages/event/{event.season}.tsx" /* webpackChunkName: "component---src-pages-event-event-season-tsx" */),
  "component---src-pages-event-index-tsx": () => import("./../../../src/pages/event/index.tsx" /* webpackChunkName: "component---src-pages-event-index-tsx" */),
  "component---src-pages-grant-tsx": () => import("./../../../src/pages/grant.tsx" /* webpackChunkName: "component---src-pages-grant-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-registration-covid-tsx": () => import("./../../../src/pages/registration/covid.tsx" /* webpackChunkName: "component---src-pages-registration-covid-tsx" */),
  "component---src-pages-registration-index-tsx": () => import("./../../../src/pages/registration/index.tsx" /* webpackChunkName: "component---src-pages-registration-index-tsx" */),
  "component---src-pages-registration-membership-tsx": () => import("./../../../src/pages/registration/membership.tsx" /* webpackChunkName: "component---src-pages-registration-membership-tsx" */),
  "component---src-pages-registration-permanent-tsx": () => import("./../../../src/pages/registration/permanent.tsx" /* webpackChunkName: "component---src-pages-registration-permanent-tsx" */),
  "component---src-pages-registration-trace-virtuelle-tsx": () => import("./../../../src/pages/registration/trace-virtuelle.tsx" /* webpackChunkName: "component---src-pages-registration-trace-virtuelle-tsx" */),
  "component---src-pages-schedule-event-schedule-id-tsx": () => import("./../../../src/pages/schedule/{event.scheduleId}.tsx" /* webpackChunkName: "component---src-pages-schedule-event-schedule-id-tsx" */),
  "component---src-pages-shop-audax-a-distance-tsx": () => import("./../../../src/pages/shop/audax-a-distance.tsx" /* webpackChunkName: "component---src-pages-shop-audax-a-distance-tsx" */),
  "component---src-pages-shop-medals-tsx": () => import("./../../../src/pages/shop/medals.tsx" /* webpackChunkName: "component---src-pages-shop-medals-tsx" */),
  "component---src-pages-symposium-2020-tsx": () => import("./../../../src/pages/symposium/2020.tsx" /* webpackChunkName: "component---src-pages-symposium-2020-tsx" */),
  "component---src-pages-symposium-2021-tsx": () => import("./../../../src/pages/symposium/2021.tsx" /* webpackChunkName: "component---src-pages-symposium-2021-tsx" */)
}

